<template>
    <section
        id="what-people-say"
        v-editable="blok"
        :style="`background-color: ${blok.Background?.color};`"
        :class="{ small: blok.Small }"
    >
        <div class="container">
            <h3 v-if="blok.Title" :class="{ mini: blok.Mini }">{{ blok.Title }}</h3>
            <div v-if="blok.Subtitle" class="trustpilot-subtitle">{{ blok.Subtitle }}</div>
            <div
                class="trustpilot"
                :class="{ small: blok.Small }"
                :style="`${blok.height ? `height: ${blok.height}px` : ''}`"
            >
                <div
                    data-locale="en-US"
                    :data-template-id="blok.Style"
                    :data-businessunit-id="blok.Businessunit_id"
                    data-style-width="100%"
                    data-theme="light"
                    :data-stars="blok.Stars"
                    class="trustpilot-widget"
                    style="position: relative"
                ></div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { ITruspilot } from '../types/components/Trustpilot';

const { blok } = defineProps<{ blok: ITruspilot }>();

if (import.meta.client) {
    if (!document.querySelector('[src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js"]')) {
        useHead({
            script: [
                {
                    src: 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js',
                    type: 'text/javascript',
                    defer: true,
                },
            ],
        });
    }
}
</script>

<style scoped lang="postcss"></style>
